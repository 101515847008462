/* styles.css */
.ag-theme-quartz-dark .ag-root-wrapper {
  border-radius: 0 !important;
}

.bold-header {
  font-weight: bold;
}

.centered-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%; /* Pastikan mencakup seluruh tinggi header */
}

.centered-header-one {
  background-color: #f3f4f6;
}

.ag-theme-alpine {
  font-family: "Verdana", "Helvetica", "Arial", "sans-serif";
  font-size: 14px;
  padding: 0.5rem;
}

.ag-theme-alpine .ag-row {
  font-size: 5px; 
}


