@tailwind base;
@tailwind components;
@tailwind utilities;

.font-poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.font-poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.font-poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.loader-dots div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
  left: 8px;
  animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
  left: 8px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
  left: 32px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
  left: 56px;
  animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

table.tableHover > tbody > tr:hover td {
  color: #000000;
  background-color: #fde68a !important;
}


/* table dashboard */
.headcol {
  position: absolute;
  width: 200px;
  left: 0;
  top: auto;

}
